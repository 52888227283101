import React from "react";
import HeaderNew from "../HeaderNew";
import FooterNew from "../FooterNew";
import Footer from "../Footer";
import Loader from "../Loader";
import FooterSection from "../FooterSection";
import { useLocation } from "react-router-dom";
import BetSlipParly from "../BetSlipParly";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import PlaysBet from "../PlaysBet";
import Message from "../Message";
import BetSlipContext from "../../context/BetSlipContext";

const LayoutNew = ({ children }) => {
  const location = useLocation();
  const { showParlyBet, parly } = useContext(AuthContext);
  const { showBetDialog, message } = useContext(BetSlipContext);
  return (
    <>
      {location?.pathname !== "/change-password" && <HeaderNew />}
      {children}
      <Loader />
      <Footer />
      {showBetDialog && <PlaysBet />}
      {message?.status && <Message />}
      {location?.pathname == "/" || location?.pathname == "/sports" ? (
        <FooterSection />
      ) : (
        ""
      )}
      {showParlyBet && parly && <BetSlipParly />}
      {location?.pathname !== "/change-password" && <FooterNew />}
    </>
  );
};

export default LayoutNew;
