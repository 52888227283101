import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import { Form, Button, InputGroup, ButtonGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";
import moment from "moment";
import { AmountArray, validationRules } from "../Utils/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import iconBkash from "../assets/images/bkash.png";
import iconRocket from "../assets/images/rocket.png";
import iconNagad from "../assets/images/nagad.png";
import iconEth from "../assets/images/eth.svg";
import iconBtc from "../assets/images/btc.svg";
import iconUpay from "../assets/images/upay.png";
import iconTrc20 from "../assets/images/trc20.svg";
import selectCheck from "../assets/images/select-check.svg";
import bankImg from "../assets/images/bank.png";
import { FaBtc } from "react-icons/fa";

const Deposit = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const [image, setImage] = useState("");
  const navigate = useNavigate();
  const [bank, setBank] = useState([]);
  const { userCoins, amounutRefresh, getProfileData,profileData } = useContext(AuthContext);
  const [isLoader, setLoader] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [despositeType, setDepositeType] = useState(null);

  const handlePaymentChange = (value) => {
    setSelectedPayment((prevValue) => (prevValue === value ? null : value));
    setSelectedBank(null);
  };

  const handleBankChange = (value) => {
    setSelectedBank((prevValue) => (prevValue === value ? null : value));
    setSelectedPayment(null); // Uncheck online payment option when bank payment is selected
  };

  const onSubmit = async (body) => {
    setLoader(true);
    let imageCheck = "";
    if (image !== "") {
      imageCheck = await uploadImage(image);
    }
    body = {
      transactionType: body?.depositType,
      amount: body?.amount,
      TransactionId: body?.transactionId,
      customerNumber: body?.customerNumber,
      TransactionFile: image !== "" ? imageCheck : "",
      bank: body?.bank,
      AccountName: body?.accountName,
      BankAccount: body?.bankAccount,
      bonus: body?.bonus,
      // receiptDate: body?.receiptDate,
    };
    const { status, data } = await apiPost(apiPath.depositAmount, body);
    if (status == 200) {
      if (data?.success) {
        toast.success(data?.message);
        amounutRefresh();
        setImage("");
        reset();
        setLoader(false);
      } else {
        toast.error(data?.message);
        setLoader(false);
      }
    } else {
      toast.error(data?.message);
      setLoader(false);
    }
  };

  const getBank = async () => {
    const { status, data } = await apiPost(apiPath.getBank, {
      type: "deposit",
    });
    if (status == 200) {
      if (data?.success) {
        setBank(data?.results?.bankList);
      }
    }
  };
  const getBankDetail = async (body) => {
    const { status, data } = await apiPost(apiPath.getBankDetail, {
      bank: body,
    });
    if (status == 200) {
      if (data?.success) {
        setValue("accountName", data?.results?.bankDetail?.account_name);
        setValue("bankAccount", data?.results?.bankDetail?.account_number);
      }
    }
  };
  console.log("--------", watch("bankAccount"));
  const uploadImage = async (obj) => {
    let form = new FormData();
    form.append("TransactionFile", obj);
    const { status, data } = await apiPost(apiPath.reciptUpload, form);
    if (status == 200) {
      return data?.path;
    } else {
      return false;
    }
  };
  useEffect(() => {
    getBank();
    setValue("depositType", "online");
  }, []);

  const { t } = useTranslation();

  let priceValue = 0;
  const priceCalculate = async (price) => {
    priceValue += price;
    setValue("amount", priceValue);
  };

  const resetPrice = async () => {
    setValue("amount", "");
  };
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  // console.log("data",paymentResponse?.request_id ,paymentResponse?.reference);
  //   useEffect(() => {
  //     const getPstatus = async () => {
  //       const { status, data } = await apiPost(apiPath.getPaymentStatus, {
  //         request_id: paymentResponse?.request_id,
  //         reference:paymentResponse?.reference
  //       });
  //       if (status == 200) {
  //         if (data?.success) {
  //           if(data?.results?.status=="success"){
  //             window.location.href =  "PaymentStatus?status="+data?.results?.status;
  //           }else if(data?.results?.status=="failed"){
  //             window.location.href =  "PaymentStatus?status="+data?.results?.status;
  //           }

  //         }
  //       }
  //     };
  //     const interval = setInterval(() => {
  //       getPstatus();

  //     }, 15000);

  //     return () => clearInterval(interval);
  //   }, [paymentResponse?.request_id && paymentResponse?.reference]);

  const [minDeposit, setMinDeposit] = useState(300);
  const [maxDeposit, setMaxDeposit] = useState(30000);


  useEffect(() => {
    if (!isEmpty(profileData) && profileData?.limitSetting.length>0) {
      setMinDeposit(profileData?.limitSetting.length>0 && profileData?.limitSetting[0]?.minDeposit)
      setMaxDeposit(profileData?.limitSetting.length>0 && profileData?.limitSetting[0]?.maxDeposit)
    }
}, [!isEmpty(profileData) && profileData?.limitSetting.length>0 && profileData?.limitSetting[0]?.minDeposit]);
  return (
    <LayoutNew>
      <div class="p-title title-box deposit-tab">
        {/* <IoMdArrowBack onClick={() => navigate(-1)} size={30} /> */}
        <div class="title w-100">
          <div class="row hidden-md-and-up mobile-header no-gutters">
            <div class="text-center mobile-header-item col col-6 selected">
              <Link
                to="/deposit"
                class="router-link-exact-active router-link-active"
              >
                <span>Deposit</span>
              </Link>
            </div>
            <div to="" class="text-center mobile-header-item col col-6">
              <Link to="/withdraw" class="">
                <span>Withdrawal</span>
              </Link>
            </div>
          </div>

          {/* {t("Deposit_Transaction")} */}
        </div>
      </div>
      <div class="main dw-p deposit-box page-content-box w-100 bg-gradual-black ">
        <div className="width70">
          <div class="walletInfo-wrapper w-100 common-box dw-box text-black">
            <div class="tol-wal-bal-box">
              <div class="item">
                <span>{t("Total_Wallet_Balance")}</span>
                <br />
                <span class="amt">BDT {userCoins?.balance || 0}</span>
                <span class="tolWalBal amt">
                  <div class="bal-loader">
                    <div class="spin-yellow spin">
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div class="item walletBalance-outer border-t mt-2 pt-1">
                <span>
                  {t("Main_Wallet")}: BDT{" "}
                  <span className="walletBalance">
                    {userCoins?.balance || 0}
                  </span>
                </span>
                <span
                  onClick={() => navigate("/deposit-history")}
                  className="walletBalance-button"
                >
                  Deposit transction history
                </span>
              </div>
            </div>
          </div>
          <div className="marquee-notification d-flex m-0 p-0">
            <marquee direction="left">
              <strong>
                নিচের অপশনগুলো থেকে বিকাশ/নগদ/রকেট সিলেক্ট করে আপনার কাঙ্খিত
                ডিপোজিট এমাউন্ট বসান ও সাবমিট বাটনে ক্লিক করুন৷ পরবর্তী পেজ থেকে
                নাম্বার কপি করে নির্দেশনা অনুযায়ী ক্যাশ আউট / সেন্ড মানি /
                পেমেন্ট করে আপনার ট্রাঞ্জেকশন আইডি করুন। ৫-১০ সেকেন্ডের মধ্যে
                আপনার ব্যালেন্স ভারিফাই হয়ে জমা হবে।
              </strong>
            </marquee>
          </div>
          <div class="usrTrans-wrapper common-box form-f mb-66">
            <div class="withdraw-form usrTrans-form">
              <Form
                onSubmit={handleSubmit(onSubmit)}
                id="deposit_form"
                class="deposit_form"
              >
                <div class="usrTrans-seperate">
                  <div class="transaction-title">
                    <span>{t("Deposit_Type")}</span>
                    <span class="important-icon">*</span>
                  </div>
                  <div class="m-auto">
                    <select
                      {...register("depositType", {
                        required: {
                          value: true,
                          message: t("Select_Deposit_Type"),
                        },
                      })}
                      id="depositType"
                    >
                      <option value="">{t("Select_Deposit_Type")}</option>
                      <option value="online" security="true">
                        {t("Online_Transfer")}
                      </option>
                    </select>
                  </div>
                  {errors?.depositType?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.depositType?.message}
                    </div>
                  )}
                </div>

                <div class="member-menu-box member-list select-group checkbox-style ">
                  <ul class="col3 ">
                    {bank?.length > 0 &&
                      bank?.map((res) => {
                        return (
                          <li onChange={() => getBankDetail(res?._id)}>
                            <input
                              type="radio"
                              value={res?._id}
                              {...register("bank")}
                            />
                            <label style={{ position: "relative" }}>
                              {res?.discount > 0 && (
                                <span className="tag-rebate-money">
                                  {res?.discount}%
                                </span>
                              )}
                              <div class="bank">
                                <img
                                  alt="bkash"
                                  src={
                                    res?.bank_name
                                      .toLowerCase()
                                      .includes("bkash")
                                      ? iconBkash
                                      : res?.bank_name
                                          .toLowerCase()
                                          .includes("nagad")
                                      ? iconNagad
                                      : res?.bank_name
                                          .toLowerCase()
                                          .includes("rocket")
                                      ? iconRocket
                                      : res?.bank_name
                                          .toLowerCase()
                                          .includes("eth")
                                      ? iconEth
                                      : res?.bank_name
                                          .toLowerCase()
                                          .includes("btc")
                                      ? iconBtc
                                      : res?.bank_name
                                          .toLowerCase()
                                          .includes("upay")
                                      ? iconUpay
                                      : bankImg
                                  }
                                />
                              </div>
                              <span>{res?.bank_name}</span>
                              <span class="item-icon">
                                <img alt="bkash" src={selectCheck} />
                              </span>
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                {!isEmpty(watch("bankAccount")) && (
                  <>
                    <div class="usrTrans-seperate bankInfoField bankInfo">
                      <div class="transaction-title">
                        <span>{t("Account_Name")}</span>
                        <span class="copyBtn bg-gradient-secondary">
                          <i class="fas fa-copy"></i>
                        </span>
                      </div>
                      <div class="transaction-option m-auto">
                        <input
                           style={{
                            border:`1px solid #e1e1e130`,
                            color:"#fff",
                            backgroundColor: "transparent",
                            WebkitBackgroundClip: "text" // For iOS browsers
                          }}
                          {...register("accountName")}
                          class="text-input"
                          id="depositAccName"
                          disabled
                        />
                      </div>
                    </div>

                    <div
                      class="usrTrans-seperate bankInfoField bankInfo "
                      style={{ position: "relative", width: `100%` }}
                    >
                      <div class="transaction-title">
                        <span>{t("Bank_Account")}</span>
                        <span class="copyBtn bg-gradient-secondary">
                          <i class="fas fa-copy"></i>
                        </span>
                      </div>
                      <div class="transaction-option m-auto">
                        <input
                          style={{
                            border:`1px solid #e1e1e130`,
                            color:"#fff",
                            backgroundColor: "transparent",
                            WebkitBackgroundClip: "text" // For iOS browsers
                          }}
                          {...register("bankAccount")}
                          disabled
                          class="text-input"
                          id="depositAccNo"
                        />
                        <div
                          className="input-group-btn"
                          style={{
                            position: "absolute",
                            right: "0",
                            bottom: "4px",
                            filter: `invert(1)`,
                          }}
                        >
                          <span
                            className="btn btn-outline-primary"
                            style={{
                              backgroundColor: "transparent",
                              border: "0",
                              padding: "8px",
                              paddingTop: "0px",
                              height: "72%",
                            }}
                          >
                            <CopyToClipboard
                              text={getValues("bankAccount")}
                              onCopy={() => setCopied(true)}
                            >
                              <Button
                                className="copy-btn"
                                style={{
                                  fontSize: `13px`,
                                  padding: `5px`,
                                  backgroundColor: `#005dac`,
                                  color: `#fff`,
                                }}
                              >
                                {isCopied ? "Copied!" : "Copy"}
                              </Button>
                            </CopyToClipboard>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="usrTrans-seperate default-type">
                      <div class="transaction-title">
                        <span>{t("Upload_Receipt")}</span>
                      </div>
                      <div class="transaction-option m-auto">
                        <div class="fileupload-box bg-gradient-secondary">
                          <span>{t("Select_an_Image")}</span>
                          <input
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              // uploadImage(e.target.files[0]);
                            }}
                            type="file"
                            class="fileInput"
                            accept="image/png, image/jiffy, image/jpeg, image/jpg"
                            id="depositReceipt"
                            name="depositReceipt"
                          />
                        </div>
                        {image !== "" && (
                          <button
                            onClick={() => setImage("")}
                            class="btn-remove removeReceipt bg-gradient-third"
                          >
                            {t("Remove_Image")}
                          </button>
                        )}
                      </div>
                      {/* {errors?.image?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.image?.message}
                      </div>
                    )} */}
                    </div>

                    <div class="usrTrans-seperate default-type">
                      <div class="transaction-title">
                        <span>{t("Transaction_ID")}</span>
                      </div>
                      <div class="transaction-option m-auto">
                        <input
                          {...register("transactionId", {
                            required: {
                              value: true,
                              message: t("enter_Transaction_ID"),
                            },
                            pattern: {
                              value: validationRules.removeWhitespace,
                              message: "Please enter only number or character",
                            },
                          })}
                          class="text-input"
                          id="depositReferId"
                        />
                      </div>
                      {errors?.transactionId?.message && (
                        <div class="transaction-errMsg text-danger depositMsg">
                          {errors?.transactionId?.message}
                        </div>
                      )}
                    </div>
                    {/* <div class="usrTrans-seperate default-type">
                    <div class="transaction-title">
                      <span>Recipient Number (যে বিকাশ নাম্বার থেকে ক্যাশআউট করেছেন ওই বিকাশ নাম্বার দিন।)</span>
                    </div>
                    <div class="transaction-option m-auto">
                      <input
                        {...register("customerNumber", {
                          required: {
                            value: true,
                            message: "Enter recipient number",
                            pattern: {
                              value: /^0\d{10}$/, // Regular expression to match 11 digits starting with 0
                              message: "Please enter a valid number starting with 0",
                            },
                          },
                        })}
                        class="text-input"
                        id="depositReferId"
                      />
                    </div>
                    {errors?.customerNumber?.message && (
                      <div class="customerNumber-errMsg text-danger depositMsg">
                        {errors?.customerNumber?.message}
                      </div>
                    )}
                  </div> */}
                    <div class="tips-info note">
                      <h5>
                        <i class="tips-icon "></i>
                        <span>
                          Dear all member, to speed up your deposit process,
                          kindly follow these steps : 1.verify the phone number
                          you used for deposit. 2. Input the correct reference
                          number. 3. Process only selected amount. 4. Attach the
                          successful Slip. Reminder: Do not save the phone
                          number displayed on Skycric website on your device to
                          avoid losing money.
                        </span>
                      </h5>
                    </div>
                  </>
                )}
                {/* <div class="usrTrans-seperate deposit-amount">
                <div class="transaction-title">
                  <span>{t("Amount")}</span>
                  <span class="important-icon">*</span>
                </div>
                <div class="transaction-option m-auto">
                  <input
                    {...register("amount", {
                      required: {
                        value: true,
                        message: t("Please_enter_amount"),
                      },
                      validate: (value) => {
                        if (value > 0) {
                          if (value > 9999999999) {
                            return t("Max_8_digit");
                          }
                        } else {
                          return t("Amount_0");
                        }
                      },
                    })}
                    type="number"
                    class="text-input"
                    id="depositAmt"
                    placeholder="0.00"
                  />
                </div>
                {errors?.amount?.message && (
                  <div class="transaction-errMsg text-danger depositMsg">
                    {errors?.amount?.message}
                  </div>
                )}
              </div> */}

                <div class="member-menu-box member-list select-group checkbox-style ">
                  <div class="title">
                    <h2>
                      <span>{t("Amount")}</span>{" "}
                      <span class="important-icon">*</span>
                      <i>৳ 300.00 - ৳ 30,000.00</i>
                    </h2>
                  </div>
                  <div class="usrTrans-seperate deposit-amount">
                    <div class="transaction-option m-auto">
                      <input
                        {...register("amount", {
                          required: {
                            value: true,
                            message: t("Please_enter_amount"),
                          },
                          validate: (value) => {
                            if (value > 0) {
                              if (Number(value) > Number(maxDeposit)) {
                                return "Amount should not be greater than "+maxDeposit;
                              } else if (value< Number(minDeposit)) {
                                return "Deposit Amount Should be greater than "+minDeposit;
                              }
                            } else {
                              return t("Amount_0");
                            }
                          },
                        })}
                        type="number"
                        class="text-input"
                        id="depositAmt"
                        placeholder="0.00"
                        onClick={() => resetPrice()}
                      />
                    </div>
                    {errors?.amount?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.amount?.message}
                      </div>
                    )}
                  </div>
                  <div class="active">
                    <ul class="col4">
                      {AmountArray?.map((item, key) => {
                        return (
                          <li onChange={() => priceCalculate(item?.amount)}>
                            <input type="radio" name="depositAmount" />
                            <label>
                              <span> {"+" + item?.amount} </span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div class="input-group money">
                    <label for="amount">৳</label>
                    <div class="input-wrap ">
                      <input
                        type="text"
                        class=" ng-untouched ng-pristine ng-valid"
                        placeholder="0.00"
                        disabled=""
                      />
                      <a class="delete-btn red"></a>
                    </div>
                  </div>
                </div>

                <div class="usrTrans-seperate">
                  <div class="transaction-option">
                    <div class="transaction-btn">
                      {/* <input
                        type="hidden"
                        id="promotionCode"
                        name="promotionCode"
                      />
                      <input
                        type="hidden"
                        id="depositBankCode"
                        name="depositBankCode"
                      />
                      <input
                        type="hidden"
                        id="depositRDay"
                        name="depositRDay"
                      />
                      <input
                        type="hidden"
                        id="depositRYear"
                        name="depositRYear"
                      />
                      <input
                        type="hidden"
                        id="depositRMonth"
                        name="depositRMonth"
                      />
                      <input
                        type="hidden"
                        id="depositRMinute"
                        name="depositRMinute"
                      />
                      <input
                        type="hidden"
                        id="depositRHour"
                        name="depositRHour"
                      /> */}
                      <button
                        type="submit"
                        disabled={isLoader}
                        className="btn-submit bg-gradient-primary text-capitalize"
                      >
                        {isLoader ? "Loading..." : t("Submit")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="marquee-notification d-flex m-0 p-0">
                  <marquee direction="left">
                    <strong>
                      এই নাম্বারে ক্যাশ আউট করতে সমস্যা হলে বিকল্প মেথড
                      বিকাশ/নগদ/রকেট ব্যাবহার করুন। অথবা কিছুক্ষন পর আবার চেষ্টা
                      করুন।
                    </strong>
                  </marquee>
                </div>
                {/* <div class="deposit-description">
                <div>
                  <span>{t("DEPOSIT_LINE1")}</span>
                </div>
                <div>
                  <span>{t("DEPOSIT_LINE2")}</span>
                </div>
                <div>
                  <span>{t("DEPOSIT_LINE3")}</span>
                </div>
              </div> */}
              </Form>
            </div>
          </div>
        </div>
        <div class="usrTrans-wrapper"></div>
      </div>
    </LayoutNew>
  );
};

export default Deposit;
